
/* Importações */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

/* Estilos CSS */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: '#FFFFFF';
  overflow-x: hidden;
}

header .wrapper {
  margin: 0;
  padding: 0;
  max-width: 100%;
  position: relative;
}

header .content-wrapper {
  float: right;
  width: 100%;
}

.student-biography {
  padding: 100px;
  text-align: center;
  justify-content: center;
}

.sidebarnew {
  text-align: center;
  position: relative;
  width: 100%;
  min-height: 300px;
  color: white !important;
  background-color: #0D3D63;
  display: flex;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  z-index: 0;
  /* Create the trapezoid shape with a slanted bottom */
  /* clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%); */
}

.remove-row-margin {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.backButton {
  position: absolute;
  top: 10px;
  left: 60px;
  font-size: 24px;
  color: #FFFFFF !important;
  z-index: 10;
}

.sidebarnew .bionew {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  /*margin-left: 30px; */
}

.sidebarnew .bionew .avatarnew {
  position: relative; /* Allow absolute positioning of title */
  /*left: 15px;*/
}

.avatarcontainer {
  position: relative; /* To allow absolute positioning of children */
  display: inline-block; /* Adjust based on your layout needs */
}

.semicircle-left {
  width: 220px;
  height: 220px;
  border: 2px solid #FC3E2C; /* Thin border to create the semicircle outline */
  border-radius: 50%; /* Creates the circular shape */
  background-color: transparent; /* No background, just the border */
  border-left-color: transparent; /* Hide the left side */
  border-bottom-color: transparent; /* Hide the bottom side */
  position: absolute; /* Position it relative to the container */
  top: 50%;
  left: -20px;
  transform: translate(-50%, -50%) rotate(45deg); /* Rotate to align it correctly */
  opacity: 0.5;
}

.avatarnew {
  position: relative; /* Base for positioning the semicircle */
  z-index: -1; /* Ensures the avatar is below the semicircle */
  left: 30px;
}

.avatarnew img{
  width: 200px;
  height: 200px; 
  object-fit: cover; 
  /* box-shadow: 0 0 20px rgba(255,255,255,0.3); */
  border-radius: 50%; 
}

.sidebarnew .bionew .titlenew {
  position: absolute;
  top: 30px; /* Align to the top of the avatar */
  left: 280px; /* Position to the right of the avatar */
  text-align: left;
}

.sidebarnew .bionew .titlenew h1 {
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  font-size: 24px;
}

.sidebarnew .bionew .titlenew h2 {
  font-size: 32px;
  margin: 0;
  font-weight: 300;
  letter-spacing: 0.8px;
}

.sidebarnew .bionew .descnew {
  margin-left: 200px; /* Adjust to ensure space from the title */
}

.sidebarnew .bionew .descnew p {
  font-size: 15px;
  font-weight: 300;
  color: #f2f2f2;
  text-align: left;
}

.sidebarnew .bionew .socialnew {
  margin-top: 50px;
  margin-left: 60px;
  font-size: larger;
  text-align: left;
}

.sidebarnew .bionew a {
  color: rgba(255,255,255,0.7);
  transition: all 0.2s linear;
}

.sidebarnew .bionew  a:hover, .sidebarnew .bionew  a:focus {
  color: rgba(255,255,255,1);
}

.sidebarnew .bionew .socialnew i {
  font-size: 24px;
}

.sidebarnew .bionew .socialnew ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.sidebarnew .bionew .socialnew ul li {
  display: inline-block;
  margin-right: 10px;
}

.socialnew a svg {
  margin-right: 8px; /* Adjust the space as needed */
}

.socialnew a {
  z-index: 10; /* Higher z-index ensures it stays above other layers */
  position: relative;
}

.sidebarnew .bionew .title {
  margin-top: 0;
}

.content-wrappernew {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.contentnew {
  padding: 60px 0 0 60px;
  margin-left: 30px;
  margin-right: 30px;
  clear: both;
  color: #777;
  /* width: 100%; */
  
  /* Grid configuration */
  display: grid;
  grid-template-columns: 2fr 1fr; /* 2/3 and 1/3 width */
  gap: 20px; /* Optional: Adjust space between columns */
}

.experience {
  grid-column: 1; /* Places the Experience component in the first column */
}

.education-skills {
  grid-column: 2; /* Places Education and Skills in the second column */
  display: grid;
  /* grid-template-rows: 1fr 1fr; Splits the second column into two rows */
  gap: 20px; /* Optional: Adjust space between rows */
  margin-left: 30px;
  margin-right: 30px;
}

.languagesnew h2,
.experiencenew h2,
.educationnew h2,
.additionalInformationnew h2 {
  color: #011F21;
}

.experiencenew {
  margin-bottom: 40px;
}

.profile-title {
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 500;
  color: #011F21; 
  text-align: center;
  margin-bottom: 20px;
}

/* .languages-title{
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 500;
  color: #011F21; 
  text-align: center;
  margin-bottom: 20px;
} */
/* 
.additionalInformation-title {
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 500;
  color: #011F21;
  text-align: center;
  margin-bottom: 20px;
} */
/* 
.experience-title {
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 500;
  color: #011F21; 
  text-align: center;
  margin-bottom: 20px; 
} */

.experience-item {
  margin-bottom: 20px;
}

.experience-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.experience-header h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: #009FE3; /* Company name and position */
  margin: 0;
}

.date-range {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #011F21; /* Date range color */
}

.additionalInformation-description,
.languages-description,
.experience-description,
.education-description{
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: #011F21; /* Description text color */
  margin: 5px 0;
}

.profile-description {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #011F21; /* Description text color */
  margin: 5px 0;
  text-align: justify;
}

.language-description {
  color: #009FE3; /* Description text color */
}


.experience-bar {
  height: 4px;
  background-color: #81C8BC; /* Color of the bar */
  width: 100%;
  margin-top: 10px;
}

.contentnew .title {
  padding-bottom: 20px;
}

.contentnew .title i {
  float: left;
  color: #233f5d;
  font-size: 26px;
  margin-right: 15px;
}

.contentnew .title h2 {
  color: #555;
}

.contentnew .title .item {
  margin-bottom: 30px;
}

.contentnew .title .item h3 {
  color: #777;
  margin-bottom: 5px;
}

.contentnew .title .item span {
  color: rgba(#233f5d,0.9);
  font-weight: 300;
}

.contentnew .title .item p {
  margin-top: 0px;
  width: 95%;
  letter-spacing: 1.1px;
  line-height: 25px;
}

.contentnew .title .w33 {
  width: 30%;
  display: inline-block;
}

.content-wrapper {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.content {
  padding: 30px 0 0 60px;
  position: relative;
  margin-left: 396px;
  margin-right: 30px;
  clear: both;
  color: #777;
  width: 60%;
}

.content .title {
  padding-bottom: 20px;
}

.content .title i {
  float: left;
  color: #233f5d;
  font-size: 26px;
  margin-right: 15px;
}

.content .title h2 {
  color: #555;
}

.content .title .item {
  margin-bottom: 30px;
}

.content .title .item h3 {
  color: #777;
  margin-bottom: 5px;
}

.content .title .item span {
  color: rgba(#233f5d,0.9);
  font-weight: 300;
}

.content .title .item p {
  margin-top: 0px;
  width: 95%;
  letter-spacing: 1.1px;
  line-height: 25px;
}

.content .title .w33 {
  width: 30%;
  display: inline-block;
}

@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

@-moz-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

/* @media only screen and (max-width: 1000px) {
  .semicircle-left {
    display: none; 
  }
} */

@media only screen and (max-width: 768px) {
  .sidebar {
    box-shadow: none;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-right: 0;
    width: 100%;
    position: relative;
  }
  .sidebar .bio {
    position: relative;
    top: 0%;
    left: 0%;
  }
  .content {
    margin-left: 0;
    position: relative;
    padding: 20px;
  }
  .content .title .item p {
    width: 100%;
    text-align: justify;
  }
  .content .title .skills {
    width: 100%;
    margin-top: 0px;
  }
  .content .title .w33 {
    width: 95%;
    display: inline-block;
  }
}


.bio > p{
  font-weight: bold;
  font-size: 30px;
  padding-bottom: 50px;
}

.bio .social a{
  text-align: left;
  display: inline-block;
  margin: 5px;
}

.bio .social a{
  color: white;
  text-decoration: none;
  font-size: large;
}

.introduction h3 {
  font-weight: bold;
  padding-top: 30px;
  padding-bottom: 15px;
}

.introduction .justified-text {
  text-align: justify;
  color: #110606;
}

.introductionnew h3 {
  font-weight: bold;
  padding-top: 30px;
  padding-bottom: 15px;
}

.introductionnew .justified-text {
  text-align: justify;
  color: #110606;
  font-size: larger;
  padding-bottom: 30px;
}


.experience .table th {
  font-weight: bold;
}


.education .table th {
  font-weight: bold;
}

.skills p {
  font-size: 30px;
  margin-bottom: 10px;
}

.tabs {
  padding: 3vh !important;
}

.filter-category.card{
  border: none !important;
  box-shadow: none !important;
}

.accordion-button::after {
  display: none !important; /* Remove o ícone de abrir/fechar */
}

.accordion-button {
  box-shadow: none !important; /* Remove a sombra do botão */
  outline: none !important;    /* Remove o contorno de foco */
}

.filter-checkbox-label {
  text-align: left;
  display: block;
}

.tabs-filters {
  float: right;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  height: auto;
  padding: 10px;
  background-color: rgba(217, 217, 217, 0.3);
}

.filters-container{
  background-color: rgba(217, 217, 217, 0) !important;
}

.filter-category {
  margin-bottom: 20px;
  background-color: rgba(217, 217, 217, 0) !important;
}

.card-header{
  background-color: rgba(217, 217, 217, 0) !important;
  border-bottom: rgba(217, 217, 217, 0) !important;
}



.listalunos {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.student_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.student_image {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.student_name {
  font-size: 15px; /* Adjust font size as needed */
  font-family: "Roboto";
  font-weight: "Regular";
  color: #0D3D63; /* Text color (remove quotes around the color) */
  margin-top: 160px;
}


.tab {
  text-align: center; /* Center align the tab content */
}

/* TopMenu.css */

.top-menu {
  top: 0; /* Stick it to the top of the viewport */
  left: 0; /* Align it to the left side */
  width: 100%; /* Make it span the entire width of the viewport */
  background-color: #6EC1E4; /* Set background color */
  padding: 10px 20px; /* Add padding */
}

.top-menu .links {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.top-menu .links a {
  text-decoration: none;
  color: #333;
}

/* Define print-specific styles */
@media print {
  body {
    margin: 0; /* Reset default margin */
    padding: 0; /* Reset default padding */
    size: A4; /* Set page size to A4 */
  }

  .sidebar {
    display: none;
  }

  .content {
    width: 80%; /* Increase the width of the content */
    margin-left: 10%; /* Adjust margin-left to move it more to the left */
    position: relative; /* Optional: Use relative positioning */
    background-color: lightblue; /* Optional: Visualize the content area */
    padding: 20px; /* Optional: Add padding if needed */
    box-sizing: border-box; /* Ensures padding and border are included in the width calculation */
}
  .content-wrapper {
    width: 100%;
  }

  .wrapper {
    width: 100%;
  }

  .wrappenew {
    width: 100%;
  }

  .print-button {
    display: none; 
  }

  .semicircle-left {
    display: none; 
  }
  
}