@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }


* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.student_image {
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.student_item:hover .student_image {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1); }

.menu_open, .open_background, .open .open_background, .open .menu_open, ul.menuprincipal li,
ul.menuprincipal li a,
ul.menucohort li,
ul.menucohort li a, ul.menuprincipal li.active a,
ul.menucohort li.active a,
ul.menuprincipal li a:hover,
ul.menucohort li a:hover, .menu_students_list, .menu_students_list.active, .menu-toggle, .profile-toggle, .menu_open .menu-toggle, .iconmenu, .menu-toggle label, .profile-toggle label, .menu-toggle:active,
.menu-toggle:focus,
.menu-toggle:hover,
.menu-toggle:hover:before, .profile-toggle.active,
.profile-toggle:focus,
.profile-toggle:hover,
.profile-toggle:hover:before, .menu_open .menu-toggle:active,
.menu_open .menu-toggle:focus,
.menu_open .menu-toggle:hover,
.menu_open .menu-toggle:hover:before, .menu-toggle:hover .iconmenu, .menu-toggle:hover label,
.profile-toggle:hover label,
.profile-toggle:focus label,
.profile-toggle.active label, .menu_open .menu-toggle:hover .iconmenu, .menu_open .menu-toggle:hover label, .home_content h1::after,
.content_tabs h2::after, .student_item:after, .student_image, .student_item:hover .student_image, .student_name label, .student_name label span, .student_item:hover .student_name label span, .student_name label:after, .student_item:hover .student_name label:after, .clearall, .clearall:hover, .timeline_item, li.timeline_item:hover, .pageback, .pageback:hover, .profile_shares a,
.profile_shares, .profile_shares a:hover, .bt_next, .bt_prev, .bt_next:hover, .bt_prev:hover, .bt_next label,
.bt_prev label, .bt_prev:hover label, .bt_next:hover label, .icon_filtersmobile, .icon_filtersmobile:hover {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

html,
body, #mainpage, #pagecontent, ul.menuprincipal,
ul.menucohort, .menucohort h2, ul.menuprincipal li,
ul.menuprincipal li a,
ul.menucohort li,
ul.menucohort li a, .header_logo, .menu-toggle, .profile-toggle, .menu_open .menu-toggle, .home_header,
.about_header,
.student_header, .about_header2, .header_title_box, .about_header_colpre, .about_header_colA, .about_header_colB, .header_title_box h2,
.about_header_colA h2,
.about_header_colB h2,
.about_header_colpre h2,
.textoh2, .abouttext1, .abouttext2, .abouttext1 p,
.abouttext2 p, .header_title_box h1,
.about_header_colA h1,
.about_header_colpre h1, .header_title_box p,
.about_header_colA p,
.about_header_colB p,
.about_header_colB .abouttext2, .home_content, .home_content h1, .home_content .content_ul, .home_content .content_tabs, .content_tabs h2, .content_ul ul, .theinternationalmba_ul,
.theexecutivemba_ul,
.thedigitalmba_ul, .theinternationalmba_ul ul,
.theexecutivemba_ul ul,
.thedigitalmba_ul ul, .content_ul ul.listalunos li, .student_item, .student_image, .student_name label, .student_name label span, .ul_filters, .filters_list, .filters_content, .filters_content h1, .filters_separator, .filters_group, .filters_group .group_item, .filters_group .group_item h2, .clearall, .content_ul ul.filters_ul,
.filters_ul, .content_ul ul.filters_ul li,
.filters_ul li, .content_ul ul.filters_ul li label,
.filters_ul li label, .about_programmes, .about_programmes h1, .programm_list, .programm_list .programm_list_item, .programm_list .programm_list_separador, .programm_list .programm_list_item h2, .programm_list .programm_list_item p, .about_school, .school_button, .bt_link, .school_textbox, .school_textbox h2, .school_textbox p, .student_profile, .student_text, .student_image_profile_left, .student_image_profile, .student_text h2, .student_text .text_profile,
.student_text .text_profile_itens,
.student_image_profile_left .text_profile_itens, .timeline_item, .class_content h2 + .timeline_item, .timeline_year, .timeline_text, .profile_shares, .profile_shares a, .student_skills, .student_timeline, .student_timeline_content h2, .student_timeline_content .timeline_item, .student_timeline_content .timeline_item .timeline_year, .student_timeline_content .timeline_item .timeline_text, .skills_list, .skills_list .skills_list_item,
.skills_list .graficos_list_item, .skills_list_item_box, .mainskills,
.careergoals,
.futureinterests,
.hobbies, .mainskills p,
.careergoals p,
.futureinterests p,
.hobbies p, .skills_list_item h2,
.graficos_list_item h2, footer,
#footer, #footer p {
  position: relative;
  float: left;
  width: 100%;
  height: auto; }

.triangulo_1, .triangulo_2, .triangulo_3, .triangulo_4, .home_content h1::after,
.content_tabs h2::after, .student_item:after, .student_name, .student_name label:after, .filters_list .filters_background, .iconshares, .bt_prev .backhover, .bt_next .backhover, .bt_next label,
.bt_prev label, .icon_filtersmobile {
  position: absolute;
  float: left;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px; }

.header_title_box, .bt_link {
  left: 50%;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%); }

.bt_next label,
.bt_prev label {
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%); }

html,
body {
  background-color: #FFFFFF; }

#mainpage {
  max-width: 100%;
  min-width: 260px;
  overflow: hidden; }

.menu_open {
  position: fixed;
  top: 64px;
  left: 150px;
  float: left;
  width: 100%;
  width: calc(100% - 300px);
  height: 90vh;
  height: calc(100vh - 120px);
  padding: 0px;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 0px;
  max-height: 0px;
  overflow: hidden; }

.header_menu.open .ocultaronopen {
  display: none; }

.open_background {
  position: fixed;
  top: 0px;
  left: 0px;
  float: left;
  width: 100%;
  height: 100vh;
  background: #192aea;
  height: 0px;
  max-height: 0px;
  overflow: hidden; }

.open .open_background {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden; }

.open .menu_open {
  padding: 0px 0px 0px 0px;
  overflow: hidden;
  height: 90vh;
  height: calc(100vh - 120px);
  max-height: 100vh; }

ul.menuprincipal,
ul.menucohort {
  list-style: none;
  margin-top: 0px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 0px; }

ul.menucohort {
  overflow-y: hidden;
  max-height: 500px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px; }

ul.menucohort {
  width: 100%;
  margin-top: 0px; }

.menucohort h2 {
  font-family: "Mirador-Bold", "Source Sans Pro", sans-serif;
  font-size: 24px;
  line-height: 26px;
  text-align: left;
  color: #FFFFFF;
  height: auto;
  margin-bottom: 20px; }

.menucohort__ h2.text-international {
  color: #00defa; }

.menucohort__ h2.text-digital {
  color: #ff5a39;
  color: #FF7B61; }

.menucohort__ h2.text-executive {
  color: #a577ed; }

ul.menuprincipal li,
ul.menuprincipal li a,
ul.menucohort li,
ul.menucohort li a {
  width: auto;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  text-transform: none;
  color: #FFFFFF; }

ul.menuprincipal li {
  margin-right: 30px; }

ul.menuprincipal li a,
ul.menucohort li a {
  border-bottom: 2px solid #485cee;
  text-decoration: none; }

ul.menucohort li {
  width: 20%;
  font-size: 14px; }

ul.menuprincipal li.active a,
ul.menucohort li.active a,
ul.menuprincipal li a:hover,
ul.menucohort li a:hover {
  border-bottom: 2px solid #FFFFFF;
  text-decoration: none; }

ul.menuprincipal li.colorblue {
  color: #485cee; }

.header_menu {
  position: absolute;
  float: left;
  width: 100%;
  height: 183px;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 4; }

.upperNav {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 4;
  margin-bottom: 25px; }

.upperNav::after {
  position: absolute;
  float: left;
  top: 0px;
  left: 0px;
  background-color: #111820;
  opacity: .25;
  width: 100%;
  height: 50px;
  content: " ";
  z-index: 1; }

.upperNav .wrapper {
  z-index: 2; }

.upperNav ul {
  position: relative;
  float: left;
  width: 100%;
  height: 50px; }

.upperNav ul li {
  position: relative;
  float: right;
  width: auto;
  height: 50px;
  display: inline-block;
  margin-left: 20px; }

.upperNav ul li a {
  position: relative;
  float: left;
  width: auto;
  height: 50px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  transition: color .1s ease-in-out;
  text-decoration: none;
  line-height: 50px; }

.upperNav ul li a:hover {
  color: #969da2; }

.lowerNav {
  position: relative;
  float: left;
  width: 100%;
  height: 110px;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 4;
  margin-bottom: 20px; }

.lowerNav ul {
  position: relative;
  float: right;
  width: auto;
  height: 50px;
  margin-top: 25px;
  z-index: 6; }

.lowerNav ul li {
  position: relative;
  float: right;
  width: auto;
  height: 50px;
  display: inline-block;
  margin-left: 38px; }

.lowerNav ul li a,
.lowerNav ul li span {
  position: relative;
  float: left;
  width: auto;
  height: 50px;
  font-style: normal;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  margin: 20px 0;
  display: inline-block;
  transition: color .3s ease-in-out;
  cursor: pointer;
  text-decoration: none; }

.lowerNav ul li a:hover,
.lowerNav ul li span:hover {
  color: #969da2; }

.menu_students_list {
  position: absolute;
  float: left;
  width: 100%;
  height: auto;
  display: inline-block;
  padding: 0px 0px;
  top: 145px;
  left: 0px;
  z-index: 5;
  max-height: 0px;
  overflow: hidden; }

.menu_students_list.active {
  max-height: 500px;
  overflow: hidden; }

.close-menu {
  position: absolute;
  float: right;
  width: 40px;
  height: 40px;
  top: 28px;
  right: 30px;
  background: transparent; }

.close-menu i {
  position: relative;
  float: right;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #ffffff;
  display: inline;
  font-size: 32px;
  line-height: 40px;
  cursor: pointer; }

.menucohort {
  background: #485cee;
  padding: 40px; }

.wrapper {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 150px;
  position: relative;
  height: auto;
  float: left; }

.header_menu .wrapper {
  max-width: 100%;
  padding: 0 150px; }

.header_logo {
  width: 284px;
  height: 64px;
  background: transparent;

  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain; }

.header_logo_powered {
  position: absolute;
  float: left;
  width: 200px;
  height: 26px;
  top: 80px;
  left: 150px;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  font-size: 12px;
  line-height: 14px; }

.menu-toggle {
  float: right;
  width: 126px;
  height: 54px;
  background: #FFFFFF;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  z-index: 1; }

.profile-toggle {
  float: left;
  width: auto;
  height: 40px;
  background: #FFFFFF;
  border-left: 4px solid #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1; }

.espaco20 {
  margin-left: 20px; }

.menu_open .menu-toggle {
  float: right;
  width: 126px;
  height: 54px;
  background: #FFFFFF;
  box-shadow: 0px 0px 0px transparent;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1; }

.iconmenu {
  position: absolute;
  float: left;
  width: 20px;
  height: 26px;
  top: 15px;
  left: 20px;
  color: #1d242b;
  display: inline;
  font-size: 22px;
  line-height: 26px;
  cursor: pointer; }

.menu-toggle label {
  position: absolute;
  float: right;
  top: 15px;
  right: 10px;
  width: 64px;
  height: 26px;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #1d242b;
  cursor: pointer; }

.profile-toggle label {
  position: relative;
  float: left;
  width: auto;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 40px;
  text-transform: uppercase;
  color: #1d242b;
  cursor: pointer; }

.menu-toggle:active,
.menu-toggle:focus,
.menu-toggle:hover,
.menu-toggle:hover:before {
  color: #fff !important;
  background-color: #1d242b; }

.profile-toggle.active,
.profile-toggle:focus,
.profile-toggle:hover,
.profile-toggle:hover:before {
  color: #fff !important;
  background-color: #1d242b; }

.menu_open .menu-toggle:active,
.menu_open .menu-toggle:focus,
.menu_open .menu-toggle:hover,
.menu_open .menu-toggle:hover:before {
  color: #1d242b !important;
  background-color: #fff; }

.menu-toggle:hover .iconmenu {
  color: #ffffff; }

.menu-toggle:hover label,
.profile-toggle:hover label,
.profile-toggle:focus label,
.profile-toggle.active label {
  color: #ffffff; }

.menu_open .menu-toggle:hover .iconmenu {
  color: #1d242b; }

.menu_open .menu-toggle:hover label {
  color: #1d242b; }

.triangulo_1 {
  left: -22px;
  top: 198px;
  width: 350px;
  height: 288px;
  background: transparent;

  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain; }

.triangulo_2 {
  float: right;
  left: auto;
  right: -20px;
  top: auto;
  bottom: -30px;
  width: 247px;
  height: 247px;
  transform: rotate(180deg);
  background: transparent;

  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain; }

.triangulo_3 {
  top: 150px;
  left: auto;
  right: -20px;
  width: 247px;
  height: 247px;
  background: transparent;

  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain; }

.triangulo_4 {
  top: -70px;
  right: 50%;
  right: calc(50% - 123px);
  left: auto;
  width: 247px;
  height: 247px;
  background: transparent;

  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: matrix(0.97, -0.08, 0.28, 1, 0, 0); }

.home_header,
.about_header,
.student_header {
  background: #192AEA;
  mix-blend-mode: normal;
  overflow: hidden; }

.home_header {
  padding-top: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  height: inherit; }

.about_header2 {
  background: #f8f8fd;
  padding-top: 60px;
  padding-bottom: 60px; }

.about_header {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 60px; }

.about_header::before {
  position: absolute;
  float: left;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(25, 42, 234, 0.6);
  z-index: 2;
  content: " "; }

.class_header::before {
  display: none; }

.about_header .wrapper {
  z-index: 3; }

.student_header {
  height: 200px; }

.header_title_box {
  padding-top: 170px;
  padding-bottom: 80px;
  max-width: 600px; }

.about_header_colpre {
  width: 100%;
  padding-top: 240px;
  padding-bottom: 0px;
  padding-right: 0px; }

.about_header_colA {
  width: 50%;
  padding-top: 30px;
  padding-bottom: 100px;
  padding-right: 24px; }

.class_header .about_header_colA {
  width: 50%;
  padding-top: 210px;
  padding-bottom: 0px;
  padding-right: 24px; }

.about_header_colB {
  width: 50%;
  padding-top: 30px;
  padding-bottom: 100px;
  padding-left: 24px; }

.header_title_box h2,
.about_header_colA h2,
.about_header_colB h2,
.about_header_colpre h2,
.textoh2 {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF; }

.abouttext1 {
  width: 50%;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 24px; }

.abouttext2 {
  width: 50%;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 24px; }

.textoh2 {
  width: auto;
  line-height: 1; }

.header_title_box h1,
.about_header_colA h1,
.about_header_colpre h1 {
  font-family: "Mirador-Bold", "Source Sans Pro", sans-serif;
  font-size: 34px;
  line-height: 76px;
  text-align: center;
  color: #FFFFFF; }

.header_title_box h1 {
  line-height: 38px; }

.about_header_colA h1 {
  font-size: 60px; }

.header_title_box h2 + h1,
.about_header_colA h2 + h1,
.about_header_colpre h2 + h1 {
  margin-top: 30px; }

.header_title_box p,
.about_header_colA p,
.about_header_colB p,
.about_header_colB .abouttext2,
.abouttext2,
.abouttext1 {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF; }

.about_header_colA p,
.about_header_colA p.abouttext1,
.abouttext1,
.abouttext2 {
  font-size: 20px;
  line-height: 34px; }

.about_header_colpre .abouttext2 {
  padding-top: 80px; }

.about_header_colA p.abouttext2,
.about_header_colB p,
.about_header_colB .abouttext2,
.abouttext2,
.abouttext2 p {
  font-size: 16px;
  line-height: 23px;
  font-weight: 400; }

.header_title_box h2 + p,
.header_title_box h1 + p {
  margin-top: 20px; }

.header_title_box p + p {
  margin-top: 20px; }

.about_header_colA h2 + p,
.about_header_colA h1 + p {
  margin-top: 40px; }

.about_header_colB h2 + p,
.about_header_colB h3 + p,
.about_header_colA p + p,
.about_header_colB p + p,
.about_header_colB h2 + .abouttext2,
.about_header_colB .abouttext2 + p {
  margin-top: 20px; }

.about_header_colB h2 + .abouttext2 {
  margin-top: 0px;
  width: auto; }

.about_header_colpre h1,
.about_header_colpre h2,
.about_header_colpre h3,
.about_header_colA h1,
.about_header_colA h2,
.about_header_colA h3,
.about_header_colB h1,
.about_header_colB h2,
.about_header_colB h3,
.about_header_colA p,
.about_header_colB p,
.about_header_colB .abouttext2,
.textoh2,
.abouttext1,
.abouttext2 {
  text-align: left; }

.about_header2 .abouttext1 {
  width: 100%;
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #1d242b; }

.about_header2 .abouttext2 {
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #1d242b; }

.about_header2 .about_header_colB,
.about_header2 .about_header_colB h2,
.about_header2 .about_header_colB p,
.about_header2 .about_header_colB .textoh2 {
  color: #1d242b; }

.about_header2 .about_header_colA,
.about_header2 .about_header_colB {
  padding-top: 0px;
  padding-bottom: 0px; }

.home_content h1 {
  font-family: "Mirador-Bold", "Source Sans Pro", sans-serif;
  font-size: 36px;
  line-height: 40px;
  text-align: left;
  color: #1d242b;
  margin-top: 50px;
  height: auto;
  padding-top: 16px;
  padding-left: 6px;
  margin-bottom: 30px;
  padding-right: 80px;
  cursor: pointer; }

.seta_click {
  position: absolute;
  float: right;
  top: 30px;
  right: 0px;
  width: 40px;
  height: 40px;
  background: transparent; }

.seta_click i {
  position: relative;
  float: left;
  width: 40px;
  height: 40px;
  text-align: right;
  line-height: 40px;
  font-size: 40px;
  font-weight: 700;
  color: #1d242b; }

h1 .seta_click i.open {
  display: block; }

h1 .seta_click i.close {
  display: none; }

h1.open .seta_click i.open {
  display: none; }

h1.open .seta_click i.close {
  display: block; }

ul li.close {
  display: none !important; }

.home_content h1::after,
.content_tabs h2::after {
  width: 50px;
  height: 6px;
  top: 0px;
  left: 0px;
  content: " ";
  background-image: none;
  background-color: #ffffff;
  border-radius: 0px;
  z-index: 2; }

.home_content h1.text-international::after,
.content_tabs h2.text-international::after {
  background-color: #00defa; }

.home_content h1.text-digital::after,
.content_tabs h2.text-digital::after {
  background-color: #ff5a39;
  background-color: #FF7B61; }

.home_content h1.text-executive::after,
.content_tabs h2.text-executive::after {
  background-color: #a577ed; }

.filters_content h1 {
  padding: 0px;
  margin: 0px; }

.filters_content h1::after {
  display: none; }

.home_content .content_ul {
  margin-top: 40px;
  height: auto; }

.home_content .content_tabs {
  margin-top: 90px;
  height: auto; }

.content_tabs h2 {
  font-family: "Mirador-Bold", "Source Sans Pro", sans-serif;
  font-size: 22px;
  line-height: 40px;
  text-align: left;
  color: #1d242b;
  margin-top: 0px;
  width: auto;
  margin-right: 40px;
  padding-top: 5px;
  opacity: 0.5;
  cursor: pointer; }

.content_tabs h2.open {
  opacity: 1; }

.content_ul ul {
  width: 100%;
  height: auto;
  min-height: 10px; }

.theinternationalmba_ul,
.theexecutivemba_ul,
.thedigitalmba_ul {
  height: auto; }

.theinternationalmba_ul ul,
.theexecutivemba_ul ul,
.thedigitalmba_ul ul {
  height: auto;
  width: 80%; }

.content_ul ul.listalunos li {
  width: 14.28%;
  width: calc(100% / 6);
  height: auto;
  padding: 6px;
  display: none; }

.content_ul ul.listalunos li.open {
  display: block; }

li.theexecutivemba .student_item:after {
  background-color: #a577ed; }

li.theinternationalmba .student_item:after {
  background-color: #00defa; }

li.thedigitalmba .student_item:after {
  background-color: #ff5a39;
  background-color: #FF7B61; }

.student_name label {
  width: 100%;
  padding: 12px;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #1d242b; }

.student_name label span {
  width: 100%;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #1d242b;
  z-index: 2; }

.student_item:hover .student_name label span {
  color: #1d242b; }

.student_name label:after {
  content: " ";
  background-image: none;
  background-color: #ffffff;
  border-radius: 0px;
  z-index: 1; }

.student_item:hover .student_name label:after {
  background-image: none;
  background-color: #ffffff; }

li.theinternationalmba .student_name label:after {
  background-image: none;
  background-color: #ffffff; }

.ul_filters {
  width: 20%;
  padding: 6px; }

.filters_list .filters_background {
  height: 100%;
  background: #f8f8fd;
  mix-blend-mode: normal;
  opacity: 1;
  border-radius: 4px;
  z-index: 1; }

.filters_content {
  padding: 20px 20px 20px 20px;
  z-index: 2; }

.filters_content h1 {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  margin: 0px;
  text-align: left;
  color: #5c6166; }

.filters_separator {
  height: 10px; }

.filters_group {
  margin-top: 15px; }

.filters_group .group_item {
  padding-left: 15px;
  background: transparent;

  background-position: left top 4px;
  background-repeat: no-repeat;
  background-size: auto;
  cursor: pointer; }

.filters_group .group_item h2 {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5c6166;
  mix-blend-mode: normal;
  opacity: 1; }

.clearall {
  width: auto;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  text-decoration-line: underline;
  color: #5c6166;
  mix-blend-mode: normal;
  opacity: 0.8;
  cursor: pointer; }

.clearall:hover {
  color: #192aea; }

.content_ul ul.filters_ul,
.filters_ul {
  width: 100%;
  list-style: none;
  display: none; }

.filters_group.open .filters_ul {
  display: block; }

.content_ul ul.filters_ul li,
.filters_ul li {
  width: 100% !important;
  height: auto;
  min-height: 20px;
  padding: 0px;
  margin-top: 14px;
  margin-right: 0px; }

.content_ul ul.filters_ul li label,
.filters_ul li label {
  width: 100%;
  height: auto;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  padding-left: 32px;
  color: #5c6166; }

.content_ul ul.filters_ul li input[type="checkbox"],
.filters_ul li input[type="checkbox"] {
  position: absolute;
  float: left;
  left: 0px;
  top: -1px;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin: 0px;
  outline: none;
  box-shadow: none;
  text-transform: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: 0px;
  resize: none;
  z-index: 2; }

.filters_ul li input[type=checkbox] + label:before {
  position: absolute;
  float: left;
  top: -1px;
  left: 0px;
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  border: 0px;
  color: #192aea;
  border-radius: 0px;
  content: "";
  margin-top: 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  border: 1px dotted #192aea; }

.filters_ul li input[type=checkbox]:checked + label:before {
  background: #192aea;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  color: #ffffff;
  text-align: center; }

.filters_ul li input[value=theexecutivemba]:checked + label:before {
  background: #a577ed;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  color: #ffffff;
  text-align: center; }

.filters_ul li input[value=theinternationalmba]:checked + label:before {
  background: #00defa;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  color: #ffffff;
  text-align: center; }

.filters_ul li input[value=thedigitalmba]:checked + label:before {
  background: #ff5a39;
  background: #FF7B61;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  color: #ffffff;
  text-align: center; }

.filters_ul li input[value=theexecutivemba] + label:before {
  border: 1px dotted #a577ed; }

.filters_ul li input[value=theinternationalmba] + label:before {
  border: 1px dotted #00defa; }

.filters_ul li input[value=thedigitalmba] + label:before {
  border: 1px dotted #ff5a39;
  border: 1px dotted #FF7B61; }

.about_programmes {
  padding-top: 60px;
  padding-bottom: 30px; }

.about_programmes + .about_programmes {
  padding-top: 0px;
  padding-bottom: 100px; }

.about_programmes h1 {
  font-family: "Mirador-Bold", "Source Sans Pro", sans-serif;
  font-size: 34px;
  line-height: 76px;
  color: #1d242b; }

.about_programmes + .about_programmes h1 {
  margin-top: 60px; }

.about_programmes + .about_programmes h1 {
  font-size: 28px; }

.programm_list {
  padding-top: 20px; }

.about_programmes + .about_programmes .programm_list {
  padding-top: 20px; }

.about_programmes + .about_programmes + .about_programmes .programm_list {
  padding-top: 0px; }

.programm_list .programm_list_item {
  width: 33.33%;
  padding-bottom: 60px; }

.programm_list .programm_list_item p a {
  color: #485cee; }

.programm_list .programm_list_item.left {
  padding-left: 0px;
  padding-right: 24px; }

.programm_list .programm_list_item.center {
  padding-left: 12px;
  padding-right: 12px; }

.programm_list .programm_list_item.right {
  padding-left: 24px;
  padding-right: 0px; }

.programm_list .programm_list_separador {
  height: 1px; }

.programm_list .programm_list_item h2 {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #1d242b; }

.programm_list .programm_list_item p {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145.7%;
  margin-top: 20px;
  color: #1d242b; }

.about_school {
  height: 100vh;
  min-height: 650px;
  background: transparent;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.school_button {
  width: 100%;
  margin-top: 20px; }

.bt_link {
  width: auto;
  height: 54px;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 48px;
  text-transform: uppercase;
  color: #485cee;
  border: 3px solid;
  border-color: currentColor;
  margin: 0 20px 0 0;
  border-radius: 30px;
  padding: 0 40px;
  cursor: pointer;
  transition: all .5s ease-in-out; }

.bt_link:hover {
  transform: translateY(2px); }

.school_textbox {
  float: right;
  width: 440px;
  margin-top: 200px; }

.school_textbox h2 {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 135.7%;
  margin-bottom: 20px;
  color: #FFFFFF; }

.school_textbox p {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 135.7%;
  color: #FFFFFF; }

.student_profile {
  margin-top: 60px; }

.student_text {
  width: 65%;
  width: calc(100% - 250px);
  padding-left: 65px;
  padding-top: 65px; }

.student_image_profile_pre {
  position: absolute;
  float: left;
  left: 0px;
  top: 0px;
  width: 370px;
  height: 435px;
  background: #192aea; }

.background-international {
  background-image: linear-gradient(90deg, #00defa 0, #192aea 60%, #192aea); }

.background-digital {
  background-image: linear-gradient(90deg, #ff5a39 0, #192aea 60%, #192aea);
  background-image: linear-gradient(90deg, #FF7B61 0, #192aea 60%, #192aea); }

.background-executive {
  background-image: linear-gradient(90deg, #a577ed 0, #192aea 60%, #192aea); }

.student_image_profile_left {
  width: 250px;
  height: auto;
  margin-top: 30px; }

.student_image_profile {
  width: 100%;
  height: 375px;
  margin-bottom: 60px;
  background: transparent;

  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.4); }

.student_text h2 {
  font-family: "Mirador-Bold", "Source Sans Pro", sans-serif;
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 10px;
  color: #485cee;
  background-image: none !important; }

.student_text h2::before {
  position: absolute;
  float: left;
  left: -85px;
  top: 39px;
  width: 65px;
  height: 3px;
  content: " ";
  background: #485cee; }

.student_text h2.background-executive::before {
  background: #a577ed; }

.student_text h2.background-international::before {
  background: #00defa; }

.student_text h2.background-digital::before {
  background: #ff5a39;
  background: #FF7B61; }

.student_text h2 .typemba {
  font-size: 24px; }

.student_text h2.background-executive .typemba {
  color: #a577ed; }

.student_text h2.background-international .typemba {
  color: #00defa; }

.student_text h2.background-digital .typemba {
  color: #ff5a39;
  color: #FF7B61; }

.student_text .text_profile,
.student_text .text_profile_itens,
.student_image_profile_left .text_profile_itens {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #1d242b;
  margin-top: 48px; }

.student_image_profile_left .text_profile_itens,
.student_image_profile_left .text_profile_itens ul li,
.student_image_profile_left .text_profile_itens ul {
  font-size: 14px; }

.student_text .text_profile_itens,
.student_image_profile_left .text_profile_itens {
  margin-top: 30px; }

.profile_shares + .text_profile_itens {
  margin-top: 50px; }

.student_image_profile_left .text_profile_itens ul li {
  width: 100%; }

.student_image_profile_left .text_profile_itens ul li:after {
  display: none; }

.text_profile_itens b {
  text-transform: uppercase;
  font-weight: 700;
  color: #485cee; }

.student_text .text_profile + .text_profile_itens {
  margin-top: 20px; }

.student_text .text_profile p + p {
  margin-top: 20px; }

.student_text .text_profile b {
  font-weight: 700;
  color: #1d242b; }

m
.student_text .text_profile_itens b {
  font-weight: 700;
  color: #485cee; }

.text_profile_itens ul {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #1d242b;
  display: inline-block;
  margin-bottom: 10px;
  display: initial; }

.text_profile_itens ul.timeline {
  width: 100%; }

.text_profile_itens ul {
  display: initial;
  text-align: left;
  display: initial;
  text-align: left;
  position: relative;
  float: left;
  width: 100%; }

.text_profile_itens ul li {
  display: initial;
  text-align: left;
  display: initial;
  text-align: left;
  position: relative;
  float: left;
  width: auto; }

.text_profile_itens b {
  position: relative;
  float: left;
  width: 100%; }

.text_profile_itens ul li:after {
  content: "/";
  color: #192aea;
  position: relative;
  margin: 0 10px;
  font-weight: 700; }

.text_profile_itens ul li:last-child:after {
  content: "";
  margin: 0; }

.text_profile_itens ul.timeline li {
  width: 100%;
  position: relative;
  float: left;
  display: block; }

.text_profile_itens ul.timeline li:after {
  content: "";
  margin: 0; }

.timeline_item {
  padding-top: 6px;
  padding-bottom: 6px;
  border-top: 1px dashed rgba(92, 97, 102, 0.4); }

.timeline_item:first-child {
  border-top: 0px dashed rgba(92, 97, 102, 0.4); }

.class_content .timeline_item {
  padding-top: 12px; }

.class_content h2 + .timeline_item {
  padding-top: 0px;
  padding-bottom: 0px;
  border-top: 0px dashed rgba(92, 97, 102, 0.4);
  margin-top: 0px; }

li.timeline_item:hover {
  background: #F8F8FD; }

.timeline_year {
  width: 110px;
  color: #1d242b;
  font-weight: 700; }

.timeline_text {
  width: auto;
  width: calc(100% - 110px);
  padding-left: 0px; }

.pageback {
  position: relative;
  float: right;
  left: auto;
  width: auto;
  height: 26px;
  right: 0px;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #1d242b;
  margin-top: 0px;
  cursor: pointer;
  border-bottom: 1px solid #1d242b; }

.pageback:hover {
  border-bottom: 1px solid #FFFFFF; }

.profile_shares {
  width: auto;
  margin-top: 24px;
  margin-right: 60px;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1d242b;
  padding-left: 32px; }

.profile_shares.last {
  margin-right: 0px; }

.profile_shares a,
.profile_shares {
  width: auto;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1d242b;
  text-decoration: none; }

.iconshares {
  width: 24px;
  height: 24px;
  top: 0px;
  left: 0px;
  background: #485cee;
  border-radius: 15px;
  font-size: 12px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  padding-left: 4px; }

.iconshares.fa-phone {
  font-size: 16px; }

.profile_shares a:hover {
  text-decoration: underline; }

.student_skills {
  margin-top: 48px; }

.student_timeline_content h2 {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #485cee; }

.class_content.student_timeline {
  margin-top: 48px; }

.student_timeline_content .timeline_item {
  margin-top: 12px; }

.student_timeline_content .timeline_item .timeline_year {
  width: 50px;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #111820;
  opacity: 0.5; }

.timelinetype2 .timeline_item .timeline_year {
  width: 90px; }

.timelinetype3 .timeline_item .timeline_year {
  width: 110px; }

.student_timeline_content .timeline_item .timeline_text {
  width: auto;
  width: calc(100% - 50px);
  padding-left: 24px;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #111820; }

.timelinetype2 .timeline_item .timeline_text {
  width: calc(100% - 90px); }

.timelinetype3 .timeline_item .timeline_text {
  width: calc(100% - 110px); }

.skills_list .skills_list_item,
.skills_list .graficos_list_item {
  width: 50%; }

.skills_list .graficos_list_item {
  width: 50%;
  margin-top: 30px; }

.skills_list .graficos_list_item:nth-child(3),
.skills_list .graficos_list_item:nth-child(4) {
  width: 100%;
  padding-top: 60px; }

.skills_list_item_box {
  min-height: 0px;
  background: transparent; }

.mainskills,
.careergoals,
.futureinterests,
.hobbies {
  margin-top: 15px;
  min-height: 194px; }

.mainskills p,
.careergoals p,
.futureinterests p,
.hobbies p {
  margin-top: 10px;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  color: #1d242b; }

.separador3 {
  position: relative;
  float: left;
  width: 100%;
  height: 1px;
  display: block; }

.separador2 {
  position: relative;
  float: left;
  width: 100%;
  height: 1px;
  display: none; }

.skills_list_item h2,
.graficos_list_item h2 {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #485cee;
  text-align: left;
  padding-left: 0px;
  padding-right: 0px; }

.skills_list_item h2.text-center,
.graficos_list_item h2.text-center {
  text-align: center; }

footer,
#footer {
  background: #111820; }

footer {
  margin-top: 120px; }

.about_school + footer {
  margin-top: 0px; }

#footer {
  padding-top: 30px;
  padding-bottom: 30px; }

#footer p {
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff; }

.text-center {
  text-align: center; }

.bt_next {
  position: fixed;
  top: 50%;
  right: -70px;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  z-index: 100;
  background-color: #F8F8FD;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  padding-top: 38px;
  padding-left: 12px;
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  transition: all .4s ease;
  box-sizing: border-box; }

.bt_prev {
  position: fixed;
  top: 50%;
  left: -70px;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  z-index: 100;
  text-align: right;
  background-color: #F8F8FD;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  padding-top: 38px;
  padding-right: 12px;
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  transition: all .4s ease;
  box-sizing: border-box; }

.bt_next:hover, .bt_prev:hover {
  background-color: #F8F8FD; }

.bt_prev .backhover {
  float: right;
  width: 50px;
  height: 100px;
  right: -50px;
  left: auto;
  z-index: 1;
  background-color: transparent;
  display: none; }

.bt_next .backhover {
  float: left;
  width: 50px;
  height: 100px;
  left: -50px;
  right: auto;
  z-index: 1;
  background-color: transparent;
  display: none; }

.bt_next label,
.bt_prev label {
  float: left;
  width: 150px;
  height: auto;
  left: -150px;
  z-index: 2;
  cursor: pointer;
  font-family: "Roboto", "Source Sans Pro", sans-serif;
  background-color: #FFFFFF;
  border: 1px solid #485cee;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 12px;
  color: #1d242b;
  opacity: 0;
  overflow: hidden; }

.bt_next label {
  float: right;
  right: -150px;
  left: auto; }

.bt_prev:hover label {
  opacity: 1;
  left: 120px;
  overflow: hidden; }

.bt_next:hover label {
  opacity: 1;
  right: 120px;
  overflow: hidden; }

.bt_prev:hover .backhover,
.bt_next:hover .backhover {
  display: block; }

.icon_filtersmobile {
  float: right;
  width: 20px;
  height: 20px;
  right: 40px;
  top: 30px;
  left: auto;
  z-index: 2;
  cursor: pointer;
  background: transparent;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: none; }

@media (max-width: 1560px) {
  ul.menucohort li {
    width: 20%;
    font-size: 12px; } }
@media (max-width: 1420px) {
  .about_programmes + .about_programmes h1 {
    margin-top: 40px; }

  .about_header_colpre .abouttext2 {
    padding-top: 55px; }

  .header_menu .wrapper,
  .wrapper {
    padding: 0px 80px 0px 80px; }

  .content_ul ul.listalunos li {
    width: 16.66%;
    width: calc(100% / 5); }

  .student_image_profile_pre {
    width: 300px; }

  .header_logo_powered {
    left: 80px;
    top: 70px; }

  .header_logo {
    width: 244px;
    height: 64px; }

  ul.menuprincipal, ul.menucohort {
    padding-left: 40px;
    padding-right: 40px; }

  .about_header_colpre h1,
  .about_header_colA h1 {
    font-size: 28px;
    line-height: 42px;
    padding-bottom: 10px; }

  .header_title_box h2, .about_header_colpre h2, .about_header_colA h2, .about_header_colB h2, .textoh2 {
    font-size: 20px;
    line-height: 24px; }

  .about_header_colB p, .about_header_colA p.abouttext2,
  .about_header_colB .abouttext2 {
    font-size: 12px;
    line-height: 20px; }

  .header_title_box p, .about_header_colA p.abouttext1,
  .abouttext1, .abouttext2 {
    font-size: 20px;
    line-height: 26px; }

  .about_header_colA p.abouttext2, .about_header_colB p, .about_header_colB .abouttext2, .abouttext2, .abouttext2 p {
    font-size: 14px; }

  .about_header {
    height: auto; }

  .about_header_colpre {
    padding-top: 190px;
    padding-bottom: 0px; }

  .about_header_colA,
  .about_header_colB {
    padding-top: 30px;
    padding-bottom: 60px; }

  .about_programmes {
    padding-top: 40px;
    padding-bottom: 40px; }

  .about_programmes + .about_programmes {
    padding-top: 0px;
    padding-bottom: 80px; }

  .programm_list .programm_list_item {
    padding-bottom: 40px; }

  .programm_list .programm_list_item h2 {
    font-size: 18px;
    line-height: 22px; }

  .school_textbox p {
    font-size: 22px; }

  .programm_list .programm_list_item p {
    font-size: 14px;
    margin-top: 15px; }

  .programm_list {
    padding-top: 20px; }

  .about_programmes + .about_programmes .programm_list {
    padding-top: 20px; }

  .about_programmes + .about_programmes + .about_programmes .programm_list {
    padding-top: 0px; }

  .menu_open {
    top: 34px;
    left: 80px;
    width: calc(100% - 160px);
    height: 90vh;
    height: calc(100vh - 60px); }

  .open .menu_open {
    height: 90vh;
    height: calc(100vh - 60px); }

  .header_title_box {
    padding-top: 150px;
    padding-bottom: 60px; }

  .triangulo_1 {
    top: 148px; }

  .home_content h1 {
    font-size: 32px;
    line-height: 66px;
    margin-top: 40px;
    height: auto; }

  .filters_content h1 {
    font-size: 18px;
    line-height: 22px;
    margin-top: 0px; }

  #footer p {
    font-size: 14px;
    line-height: 16px; }

  #footer {
    padding-top: 32px;
    padding-bottom: 24px; }

  .pageback {
    font-size: 18px; }

  .home_content .content_ul {
    margin-top: 24px; }

  .iconshares {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    font-size: 12px;
    line-height: 26px; }

  .profile_shares {
    margin-top: 20px;
    font-size: 14px;
    line-height: 26px;
    padding-left: 36px; }

  .profile_shares a,
  .profile_shares {
    font-size: 14px;
    line-height: 26px; }

  .skills_list_item_box {
    min-height: 200px;
    padding: 25px 20px 20px 20px;
    padding: 30px 30px 30px 30px; }

  .skills_list_item h2,
  .student_timeline_content h2,
  .graficos_list_item h2 {
    font-size: 18px;
    line-height: 22px; }

  .top5strengths .strength_1, .top5strengths .strength_2, .top5strengths .strength_3, .top5strengths .strength_4, .top5strengths .strength_5 {
    font-size: 12px; }

  .skills_list_item_box.topstrengths {
    padding: 40px 20px 20px 20px; }

  .mainskills p,
  .careergoals p,
  .futureinterests p,
  .hobbies p,
  .student_timeline_content .timeline_item .timeline_year,
  .student_timeline_content .timeline_item .timeline_text {
    font-size: 14px; }

  .top5strengths .polygon,
  .top5strengths .quadrado,
  .top5strengths .triangulo {
    background-position: center center;
    background-size: auto 90%; }

  ul.menuprincipal li, ul.menuprincipal li a, ul.menucohort li, ul.menucohort li a {
    font-size: 14px;
    line-height: 26px; }

  ul.menucohort li {
    width: 25%; } }
@media (max-width: 1180px) {
  .content_ul ul.listalunos li {
    width: 20%;
    width: calc(100% / 4); } }
@media (max-width: 1080px) {
  ul.menucohort li {
    width: 33.33%;
    font-size: 10px; }

  .skills_list .skills_list_item {
    width: 40%; }

  .skills_list .skills_list_item.timelinetype2,
  .skills_list .skills_list_item.timelinetype3 {
    width: 60%;
    padding-top: 0px; }

  .skills_list .skills_list_item.academicback {
    width: 40%; }

  .skills_list .skills_list_item.futureprof,
  .skills_list .skills_list_item.hobbiesskills {
    width: 30%; }

  .separador3 {
    display: none; }

  .separador2 {
    display: block; }

  .skills_list .skills_list_item.timelinetype2,
  .skills_list .skills_list_item.timelinetype3 {
    padding-top: 0px; }

  .content_ul ul.listalunos li {
    width: 20%;
    width: calc(100% / 3); } }
.mobile {
  display: none; }

.sodesktop {
  display: block; }

.somobile {
  display: none; }

@media (max-width: 960px) {
  .theinternationalmba_ul ul,
  .theexecutivemba_ul ul,
  .thedigitalmba_ul ul {
    width: 75%; }

  .ul_filters {
    width: 25%; }

  .somobile {
    display: block; }

  .sodesktop {
    display: none; }

  .content_tabs h2 {
    font-size: 20px;
    line-height: 40px; }

  .student_text h2 .typemba {
    font-size: 18px; }

  .header_menu .wrapper,
  .wrapper {
    padding: 0px 60px 0px 60px; }

  .header_logo_powered {
    left: 60px;
    top: 60px; }

  .header_logo {
    width: 204px;
    height: 54px; }

  .menu_students_list {
    display: none; }

  .lowerNav ul {
    display: none; }

  .menu_open {
    left: 60px;
    width: calc(100% - 120px); }

  ul.menuprincipal, ul.menucohort {
    padding-left: 30px;
    padding-right: 30px; }

  .student_image {
    height: 210px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover; }

  .student_image_profile {
    max-width: 180px;
    width: 100%; }

  .student_image_profile_left {
    width: 100%;
    margin-top: 30px; }

  .student_text.mobile {
    width: 100%;
    width: calc(100% - 230px);
    padding: 0px;
    margin-left: 50px;
    display: block; }

  .student_image_profile {
    width: 100%;
    height: 270px;
    margin-bottom: 0px; }

  .student_image_profile_pre {
    width: 220px;
    height: 330px; }

  .pageback {
    margin-top: -30px; }

  .profile_shares {
    width: 50%;
    width: calc(100% - 230px);
    margin-right: 0px;
    margin-left: 50px; }

  .student_text.ocultarmobile h2 {
    display: none; }

  .student_text.ocultarmobile {
    width: 100%;
    padding-left: 0px; }

  .content_ul ul.listalunos li {
    width: 25%;
    width: calc(100% / 2); } }
@media (max-width: 860px) {
  .ul_filters {
    display: none; }

  .content_ul ul.listalunos li {
    width: 33.33%;
    width: calc(100% / 3); }

  .theinternationalmba_ul ul,
  .theexecutivemba_ul ul,
  .thedigitalmba_ul ul {
    width: 100%; }

  .student_text .text_profile {
    margin-top: 30px; }

  .student_image_profile_pre {
    width: 210px; }

  .content_tabs h2 {
    font-size: 18px;
    line-height: 40px; }

  .skills_list .graficos_list_item:nth-child(1),
  .skills_list .graficos_list_item:nth-child(2),
  .skills_list .graficos_list_item:nth-child(3) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 24px;
    margin: 0px; }

  .skills_list .graficos_list_item:nth-child(3) {
    padding-bottom: 0px; }

  .student_text h2 {
    font-size: 28px;
    line-height: 38px; }

  .menu_open {
    top: 34px;
    left: 40px;
    width: calc(100% - 80px); }

  ul.menuprincipal, ul.menucohort {
    padding-left: 30px;
    padding-right: 30px; }

  .openfilter .ul_filters {
    display: block;
    position: absolute;
    float: left;
    width: 100%;
    height: auto;
    top: -30px;
    left: 0px;
    z-index: 2;
    background: #ffffff; }

  .icon_filtersmobile.open {
    display: block; }

  .about_school {
    height: 100vh;
    min-height: 250px;
    max-height: 400px; }

  .school_textbox {
    width: 50%;
    margin-top: 80px; }

  ul.menuprincipal li.colorblue {
    width: 100%;
    margin-top: 20px; }

  ul.menucohort {
    margin-top: 10px; }

  footer {
    margin-top: 40px; }

  .about_school + footer {
    margin-top: 0px; }

  .header_menu .wrapper, .wrapper {
    padding: 0px 50px 0px 50px; }

  .header_logo_powered {
    left: 50px; }

  .home_content .content_tabs {
    margin-top: 60px; } }
@media (max-width: 760px) {
  .about_header_colpre .abouttext2 {
    padding-top: 20px; }

  .theinternationalmba_ul ul,
  .theexecutivemba_ul ul,
  .thedigitalmba_ul ul {
    width: 100%; }

  .about_header {
    padding-bottom: 40px; }

  .about_header2 {
    padding-top: 40px;
    padding-bottom: 40px; }

  .home_content h1::after, .content_tabs h2::after {
    width: 100%; }

  .header_menu .wrapper, .wrapper {
    padding: 0px 30px 0px 30px; }

  .header_logo_powered {
    left: 30px; }

  .content_tabs h2 {
    width: 33.33%;
    font-size: 14px;
    line-height: 20px;
    margin-right: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    text-align: center; }

  .content_ul ul.listalunos li {
    width: 33.33%;
    width: calc(100% / 3); }

  .header_title_box h1, .about_header_colpre h1, .about_header_colA h1 {
    font-size: 30px;
    line-height: 38px; }

  .header_title_box p, .about_header_colA p.abouttext1, .abouttext1, .abouttext2 {
    font-size: 14px;
    line-height: 22px; }

  .home_content h1 {
    font-size: 26px;
    line-height: 56px;
    margin-top: 30px;
    padding-right: 40px; }

  .seta_click {
    top: 18px; }

  .seta_click i {
    font-size: 30px; }

  .filters_content h1 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 0px; }

  .about_programmes h1 {
    font-size: 26px;
    line-height: 56px; }

  .about_programmes + .about_programmes h1 {
    font-size: 20px; }

  .programm_list {
    padding-top: 10px; }

  .about_programmes + .about_programmes .programm_list {
    padding-top: 10px; }

  .about_programmes + .about_programmes + .about_programmes .programm_list {
    padding-top: 10px; }

  .programm_list .programm_list_item h2 {
    font-size: 16px;
    line-height: 20px; }

  .icon_filtersmobile {
    right: 30px;
    top: 30px; }

  .menu-toggle,
  .menu_open .menu-toggle {
    width: 116px;
    height: 44px; }

  .iconmenu {
    top: 10px; }

  .menu-toggle label {
    top: 10px;
    right: 10px;
    font-size: 14px;
    width: 54px; }

  .about_header_colpre {
    padding-top: 160px; }

  .about_header_colA, .about_header_colB {
    padding-top: 0px;
    padding-bottom: 40px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px; }

  .abouttext1,
  .abouttext2 {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px; }

  .about_header_colA h1 {
    padding-bottom: 0px; }

  .abouttext1 h1,
  .abouttext1 h1 + p,
  .abouttext2 {
    padding-top: 20px;
    padding-bottom: 0px; }

  .about_header_colA {
    padding-top: 40px;
    padding-bottom: 40px; }

  .about_header_colB {
    padding-top: 40px;
    padding-bottom: 0px; }

  .about_header_colA h2 + p, .about_header_colA h1 + p {
    margin-top: 20px; }

  .header_title_box h2 + h1,
  .header_title_box h2 + h1, .about_header_colpre h2 + h1, .about_header_colA h2 + h1 {
    margin-top: 30px; }

  .bt_link {
    height: 44px;
    font-size: 14px;
    line-height: 44px;
    padding-left: 15px;
    padding-right: 15px; }

  .about_programmes {
    padding-top: 40px;
    padding-bottom: 40px; }

  .about_programmes + .about_programmes {
    padding-top: 0px;
    padding-bottom: 40px; }

  .programm_list .programm_list_item {
    padding-bottom: 30px; }

  .school_textbox p {
    font-size: 18px; }

  .about_header_colB {
    display: none; }

  .about_header2 {
    display: block; }

  .about_header2 .about_header_colB {
    display: block;
    color: #1d242b;
    margin-top: 30px; }

  .about_header2 .about_header_colB h1,
  .about_header2 .about_header_colB h2,
  .about_header2 .about_header_colB p, .textoh2 {
    color: #1d242b; }

  .about_header2 .about_header_colB h2, .textoh2 {
    font-size: 26px;
    line-height: 30px;
    font-family: "Mirador-Bold", "Source Sans Pro", sans-serif; }

  .programm_list .programm_list_item p {
    font-size: 12px; } }
.dividembatextomobile {
  display: none; }

@media (max-width: 680px) {
  .home_content .content_tabs {
    margin-top: 40px; }

  .dividembatextomobile {
    display: block; }

  .student_text .text_profile, .student_text .text_profile_itens {
    font-size: 14px;
    line-height: 24px;
    margin-top: 24px; }

  .student_text .text_profile {
    margin-top: 0px; }

  .text_profile_itens ul {
    font-size: 14px;
    line-height: 24px; }

  footer {
    margin-top: 30px; }

  .about_school + footer {
    margin-top: 0px; }

  .about_header_colB h2,
  .about_header2 .about_header_colB h2, .textoh2 {
    font-size: 18px;
    line-height: 22px; }

  .skills_list .skills_list_item.right {
    padding-top: 0px; }

  ul.menucohort li {
    width: 50%; }

  .header_menu {
    height: 163px; }

  .student_header {
    height: 165px; }

  .header_logo {
    width: 210px;
    height: 34px; }

  .content_ul ul.listalunos li {
    width: 33.33%;
    width: calc(100% / 3); }

  .header_menu .wrapper, .wrapper {
    padding: 0px 30px 0px 30px; }

  .student_image_profile_pre {
    width: 190px;
    height: 330px; }

  .header_logo_powered {
    left: 30px;
    top: 45px;
    font-size: 10px; }

  .menu_open .menu-toggle,
  .menu-toggle {
    width: 100px;
    height: 34px; }

  .iconmenu {
    width: 20px;
    height: 26px;
    top: 5px;
    left: 15px;
    font-size: 18px;
    line-height: 26px; }

  .menu-toggle label {
    top: 5px;
    right: 15px;
    font-size: 12px;
    width: 34px; }

  .header_title_box {
    padding-top: 90px;
    padding-bottom: 30px; }

  .about_header_colpre {
    padding-top: 150px;
    padding-bottom: 0px; }

  .about_header_colA {
    padding-top: 30px;
    padding-bottom: 30px; }

  .header_title_box {
    padding-top: 120px;
    padding-bottom: 30px; }

  .header_title_box h2 {
    font-size: 12px;
    line-height: 14px; }

  .about_header_colpre h2,
  .about_header_colA h2 {
    font-size: 14px;
    line-height: 16px; }

  about_header_colB h2, .textoh2 {
    font-size: 16px;
    line-height: 18px; }

  .header_title_box h1, .about_header_colpre h1, .about_header_colA h1, .about_header_colB h1 {
    font-size: 20px;
    line-height: 28px; }

  .header_title_box h2 + h1, .header_title_box h2 + h1, .about_header_colpre h2 + h1, .about_header_colA h2 + h1 {
    margin-top: 20px; }

  .header_title_box p, .about_header_colA p.abouttext1, .abouttext1, .abouttext2, .about_header_colA p.abouttext2,
  .about_header_colB .abouttext2, .about_header_colB p, .about_header_colB p, .about_header_colB .abouttext2, .abouttext2, .abouttext2 p {
    font-size: 12px;
    line-height: 20px; }

  .home_content h1 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 30px; }

  .icon_filtersmobile {
    right: 30px;
    top: 15px; }

  .home_content .content_ul {
    margin-top: 14px; }

  .theinternationalmba_ul ul,
  .theexecutivemba_ul ul,
  .thedigitalmba_ul ul {
    width: 100%; }

  .student_name label,
  .student_name label span {
    font-size: 10px;
    line-height: 14px; }

  .filters_content {
    z-index: 2; }

  .home_content h1 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 30px;
    padding-right: 40px; }

  .seta_click {
    top: 15px;
    height: 20px;
    line-height: 20px; }

  .seta_click i {
    font-size: 30px;
    height: 20px;
    line-height: 20px; }

  .filters_content h1 {
    margin-top: 10px; }

  .filters_group {
    margin-top: 10px; }

  .filters_content h1 {
    font-size: 14px;
    line-height: 18px;
    margin-top: 0px; }

  .content_ul ul.filters_ul li label, .filters_ul li label {
    font-size: 12px;
    line-height: 24px; }

  .about_header_colB {
    padding-top: 30px;
    padding-bottom: 0px; }

  .about_programmes {
    padding-top: 30px;
    padding-bottom: 30px; }

  .about_programmes h1 {
    font-size: 18px;
    line-height: 22px; }

  .about_programmes + .about_programmes h1 {
    font-size: 14px; }

  .programm_list .programm_list_item h2 {
    font-size: 12px;
    line-height: 16px; }

  .programm_list .programm_list_item,
  .programm_list .programm_list_item.left,
  .programm_list .programm_list_item.center,
  .programm_list .programm_list_item.right {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px; }

  .school_button {
    margin-top: 0px; }

  .bt_link {
    height: 34px;
    font-size: 12px;
    line-height: 34px;
    padding-left: 15px;
    padding-right: 15px; }

  .school_textbox {
    width: 100%;
    padding-left: 50%;
    margin-top: 80px; }

  ul.menucohort {
    margin-top: 5px; }

  ul.menuprincipal li, ul.menuprincipal li a, ul.menucohort li, ul.menucohort li a {
    font-size: 12px;
    line-height: 24px;
    padding-top: 5px;
    padding-bottom: 5px; }

  .header_title_box p + p {
    margin-top: 10px; }

  #footer {
    padding-top: 24px;
    padding-bottom: 20px; }

  #footer p {
    font-size: 12px;
    line-height: 14px;
    padding-left: 20px;
    padding-right: 20px; }

  .student_text h2 {
    font-size: 28px;
    line-height: 38px;
    padding-top: 10px; }

  .student_text h2 .typemba {
    font-size: 18px; }

  ul.menuprincipal li, ul.menuprincipal li a, ul.menucohort li, ul.menucohort li a {
    padding-top: 0px;
    padding-bottom: 0px; }

  ul.menuprincipal, ul.menucohort {
    padding-left: 20px;
    padding-right: 20px; }

  ul.menucohort li {
    padding-left: 0px !important;
    padding-right: 0px !important; }

  .school_textbox {
    width: 75%;
    margin-top: 80px;
    padding-left: 0px; }

  .bt_next {
    right: -80px;
    padding-left: 6px; }

  .bt_prev {
    left: -80px;
    padding-right: 6px; } }
@media (max-width: 520px) {
  .bt_prev {
    left: -85px;
    padding-right: 4px;
    margin-top: -50px;
    padding-top: 40px; }

  .bt_prev img {
    width: 10px;
    height: 18px; }

  .bt_next {
    right: -85px;
    padding-left: 4px;
    margin-top: -50px;
    padding-top: 40px; }

  .bt_next img {
    width: 10px;
    height: 18px; }

  .header_menu .wrapper, .wrapper {
    padding: 0px 20px 0px 20px; }

  .header_logo_powered {
    left: 20px; }

  .content_tabs h2 {
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px; }

  .open .menu_open {
    height: calc(100vh - 50px); }

  .menu_open {
    top: 25px;
    left: 25px;
    width: calc(100% - 50px); }

  .icon_filtersmobile {
    right: 25px; }

  ul.menuprincipal {
    padding-left: 25px;
    padding-right: 25px; }

  ul.menucohort {
    padding-left: 15px;
    padding-right: 15px; }

  .student_image_profile_pre {
    width: 185px;
    height: 330px; }

  .content_ul ul.listalunos li {
    width: 50%;
    width: calc(100% / 2); }

  .header_logo_powered {
    left: 25px; }

  ul.menucohort li {
    padding-left: 10px !important;
    padding-right: 10px !important; }

  .header_logo {
    width: 160px;
    height: 34px; }

  .iconmenu {
    left: 10px; }

  .menu-toggle label {
    right: 10px; }

  .menu_open .menu-toggle, .menu-toggle {
    width: 75px;
    height: 34px; }

  .content_ul ul.filters_ul li, .filters_ul li {
    margin-top: 10px;
    width: 100%; }

  .student_text.mobile {
    margin-left: 30px; }

  .student_text h2 {
    font-size: 20px;
    line-height: 30px; }

  .student_text h2 .typemba {
    font-size: 14px; }

  ul.menucohort li, ul.menucohort li a {
    font-size: 10px; }

  ul.menuprincipal li, ul.menuprincipal li a, ul.menucohort li, ul.menucohort li a {
    line-height: 20px; }

  .header_menu {
    height: 90px;
    z-index: 4; }

  .about_header_colpre {
    padding-top: 140px;
    padding-bottom: 0px; }

  .about_header_colA {
    padding-top: 30px;
    padding-bottom: 30px; }

  .header_title_box {
    padding-top: 110px;
    padding-bottom: 30px; } }
.textoh2 {
  width: auto;
  line-height: 1; }

@media (max-width: 1360px) {
  .class_header .about_header_colA, .class_header .about_header_colB {
    padding-top: 170px;
    padding-bottom: 0px; } }
@media (max-width: 760px) {
  .class_header .about_header_colA {
    padding-bottom: 0px; }

  .student_image_profile {
    max-width: 33%;
    width: 100%; }

  .student_text.mobile {
    width: 67%;
    width: calc(67% - 30px);
    margin-left: 30px;
    padding-left: 0px; } }
@media (max-width: 760px) and (max-width: 680px) {
  .class_header .about_header_colA {
    padding-top: 160px;
    padding-bottom: 0px; } }
@media (max-width: 760px) {
  .profile_shares {
    width: 67%;
    width: calc(67% - 30px);
    margin-right: 0px;
    margin-left: 30px;
    padding-left: 35px; }

  .profile_shares a,
  .profile_shares {
    font-size: 12px; }

  .student_text h2::before {
    left: -50px;
    width: 40px; }

  .student_image_profile_pre {
    width: 30%;
    height: 330px; } }
@media (max-width: 520px) {
  .class_header .about_header_colA {
    padding-top: 130px;
    padding-bottom: 0px; }

  .upperNav {
    height: 30px;
    margin-bottom: 0px; }

  .upperNav ul {
    height: 30px; }

  .upperNav ul li {
    height: 50px; }

  .upperNav ul li a {
    height: 30px;
    line-height: 30px; }

  .upperNav::after {
    height: 30px; }

  .lowerNav {
    height: 100px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0px; }

  .header_menu {
    height: 130px; }

  .home_header {
    padding-top: 30px; }

  .about_header_colpre {
    padding-top: 130px; }

  .header_logo_powered {
    top: 40px; }

  .student_header {
    height: 130px; } }
.graficos_list_item h2,
.theinternationalmba h2 {
  color: #00defa; }

.thedigitalmba h2 {
  color: #ff5a39;
  color: #FF7B61; }

.theexecutivemba h2 {
  color: #a577ed; }

.graficos_list_item h2,
.theinternationalmba h2,
.thedigitalmba h2,
.theexecutivemba h2 {
  color: #1d242b; }

.profile-toggle.theinternationalmba {
  border-left: 4px solid #00defa; }

.profile-toggle.thedigitalmba {
  border-left: 4px solid #ff5a39;
  border-left: 4px solid #FF7B61; }

.profile-toggle.theexecutivemba {
  border-left: 4px solid #a577ed; }

.profile-toggle.active.theinternationalmba,
.profile-toggle.theinternationalmba:focus,
.profile-toggle.theinternationalmba:hover,
.profile-toggle.theinternationalmba:hover:before {
  background-color: #00defa; }

.profile-toggle.active.thedigitalmba,
.profile-toggle.thedigitalmba:focus,
.profile-toggle.thedigitalmba:hover,
.profile-toggle.thedigitalmba:hover:before {
  background-color: #ff5a39;
  background-color: #FF7B61; }

.profile-toggle.active.theexecutivemba,
.profile-toggle.theexecutivemba:focus,
.profile-toggle.theexecutivemba:hover,
.profile-toggle.theexecutivemba:hover:before {
  background-color: #a577ed; }

@media (max-width: 760px) {
  .skills_list_item_box {
    padding: 0px 0px 0px 0px; }

  .profile-toggle {
    width: 33.33%;
    margin-left: 0px !important;
    height: auto; }

  .profile-toggle label {
    width: 100%;
    height: auto;
    padding-top: 5px;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px; }

  .profile-toggle.theinternationalmba {
    border-left: 0px;
    border-top: 4px solid #00defa; }

  .profile-toggle.thedigitalmba {
    border-left: 0px;
    border-top: 4px solid #ff5a39;
    border-top: 4px solid #FF7B61; }

  .profile-toggle.theexecutivemba {
    border-left: 0px;
    border-top: 4px solid #a577ed; } }
@media (max-width: 480px) {
  .class_content.student_timeline {
    margin-top: 30px; }

  .student_skills {
    margin-top: 30px; }

  .about_header {
    padding-bottom: 30px; }

  .profile-toggle label {
    font-size: 10px; }

  .student_profile {
    margin-top: 40px; } }
@media (max-width: 400px) {
  .profile_shares {
    width: 67%;
    width: calc(67% - 20px);
    margin-right: 0px;
    margin-left: 20px;
    padding-left: 30px;
    line-height: 20px; }

  .iconshares {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    font-size: 10px;
    line-height: 20px; }

  .profile_shares a,
  .profile_shares {
    font-size: 10px;
    line-height: 20px; }

  .profile-toggle {
    width: 100%;
    margin-bottom: 10px; }

  .content_tabs h2 {
    width: 100%;
    padding: 10px;
    opacity: 1;
    margin-bottom: 10px; }

  .content_tabs h2.text-international.open {
    background-color: #00defa;
    color: #ffffff; }

  .content_tabs h2.text-executive.open {
    background-color: #a577ed;
    color: #ffffff; }

  .content_tabs h2.text-digital.open {
    background-color: #ff5a39;
    background-color: #FF7B61;
    color: #ffffff; } }
@media (max-width: 380px) {
  .content_ul ul.listalunos li {
    width: 100%; } }
.theinternationalmba_ul,
.theexecutivemba_ul,
.thedigitalmba_ul {
  display: none; }

.theinternationalmba_ul.open,
.theexecutivemba_ul.open,
.thedigitalmba_ul.open {
  display: block; }
